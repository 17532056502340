/* src/ImageUpload.css */

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-button {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .upload-button input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .preview img {
    max-width: 100%;
    margin: 10px;
    border-radius: 5px;
  }
  
  @media (max-width: 600px) {
    .upload-button,
    button[type="submit"] {
      width: 100%;
    }
  
    .preview img {
    }
  }
  

  input[type="file"] {
    outline: none;
    padding: 4px;
    margin: -4px;
  }
  
  input[type="file"]:focus-within::file-selector-button,
  input[type="file"]:focus::file-selector-button {
    outline: 2px solid #0964b0;
    outline-offset: 2px;
  }
  
  input[type="file"]::before {
    top: 16px;
  }
  
  input[type="file"]::after {
    top: 14px;
  }
  
  /* ------- From Step 2 ------- */
  
  input[type="file"] {
    position: relative;
    margin-bottom: 20px;
  }
  
  input[type="file"]::file-selector-button {
    width: 136px;
    color: transparent;
  }
  
  /* Faked label styles and icon */
  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    /*   top: 11px; */
    left: 40px;
    color: #0964b0;
    content: "Subir fotos";
  }
  
  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    /*   top: 10px; */
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }
  
  /* ------- From Step 1 ------- */
  
  /* file upload button */
  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }
  
  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }
  
  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .upload-container label{
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    /* background-color: #28a745; */
    border: none;
    border-radius: 10px;
  }