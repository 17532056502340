
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
.login-leftColumn{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #FFFFFF;
  flex-direction: column;
  text-align: left;
  padding-left: 20%;
}
.login-leftColumn h2{
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  text-align: left !important;
  margin-bottom: 10px;
}
.login-leftColumn h5{
  font-family: 'Nunito Sans', sans-serif;
  font-weight: lighter;
  font-size: 24px;
}

.login-rightColumn {
  display: flex;
  justify-content: center;
  height: 100%;
  align-content: center;
}
.login-page {
  margin: auto;
}
.login-page .form > img{
  max-width: 90%;
  margin-bottom: 20px;
}
.login-page .form > h4 {
  margin: 15px 0;
}
.login-page .form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  width: 360px;
  min-height: 600px;
  margin: 0 auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.login-page .form input {
  font-family: "Nunito Sans", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-page .form button {
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #1F1F39;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  border-radius: 10px;
  cursor: pointer;
}
.login-page .form .message {
  margin-bottom: 10px;
  color: #1F1F39;
  font-size: 12px;
}
.login-page .form .message a {
  color: #1F1F39;
  text-decoration: underline;
  font-weight: bold;
}
.login-page .form .register-form {
  display: none;
}
.login-page .container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.login-page .container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.login-page .container .info {
  margin: 50px auto;
  text-align: center;
}
.login-page .container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.login-page .container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.login-page .container .info span a {
  color: #000000;
  text-decoration: none;
}
.login-page .container .info span .fa {
  color: #EF3B3A;
}