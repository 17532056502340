.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 40px 25px;
  border-radius: 8px;
    max-width: 700px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content button {
  margin: 5px;
}
.modal-content h3{
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
}
.modal-content button{
  width: 100%;
  border: 1px solid #C9CDD2;
  border-radius: 10px;
  padding: 10px 20px;
}
.modal-content button.success{
  background: #21BDA2;
  color: #1F1F39;
  font-weight: 600;
}
.modal-content button.cancel {
  background: #ffffff;
}