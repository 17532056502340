.form {
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
}
.form h2{
  font-weight: 700;
}
.form > div,
.form .form-group{
  width: 100%;
}
  .form-group {
    margin-bottom: 30px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .submit-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  #--react--toast-container{
    top: 45%!important;
  }
@media (max-width: 600px) {
  .formContainer{
    flex-direction: column !important;
    height: 100%;
  }
  .formContainer > div.previewImgs{
    max-width: none!important;
    padding: 10%;
    margin-top: -10%;
  }
  .modal-content{
    height: 100%;
    border-radius: 0!important;
  }
  .App{
    height: auto!important;
  }
}

.--react--toast-alert{
  box-shadow: none!important;
  font-family: "Nunito Sans", sans-serif;
}

.formContainer{
  position: relative;
  width: 100%;
  display: flex!important;
  justify-content: center;
  flex-direction: row;
  height: 100%;

  align-items: center;
}