/* src/App.css */
@import url('https://fonts.cdnfonts.com/css/nunito-sans');

body{
  background-color: #1F1F39!important;

  /* background-image: url(../img/back-pattern.jpg); */
}
.App {
    text-align: center;
    background-color: #1F1F39!important;
    font-family: "nunito sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .App-header {
    background-color: #1F1F39;
    padding: 20px;
    color: white;
  }
  
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  